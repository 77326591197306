@import '../../../styles/variables';

.filter-bar {
    padding-left: 15px;
    height: 50px;

    .filter-on-text {
        margin-right: 12px;
    }

    .filter-clear-container {
        cursor: pointer;
        padding-right: 15px;
        color: $neutral-3-color;

        @media screen and (max-width: 767px) {
            padding-right: 0px;
        }

        .filter-clear-icon {
            margin-top: 2px;
            margin-right: 5px;
            color: $neutral-3-color;
        }
    }

    @media screen and (max-width: 767px) {
        height: auto;
        padding-left: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        justify-content: space-between;
        align-items: baseline;
    }
}