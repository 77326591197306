.forms-card {
    padding: 0px !important;

    .forms-header {
        padding: 15px;

        @media screen and (max-width: 767px) {
            padding: 10px;
        }
    }

    .mobile-single-form {
        padding: 10px;

        .date {
            margin-bottom: 5px;
        }

        .info {
            font-size: 13px;
            line-height: 20px;
        }
    }
}