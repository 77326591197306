@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
.base-table td.can-select {
  cursor: pointer;
}

.loa-table-container .ui.table thead th {
  background: none;
}
.loa-table-container .ui.table td {
  padding: 0px;
}
.loa-table-container .loa-table .driver-name {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
}
.loa-table-container .loa-table .driver-header {
  font-size: 14px;
  font-weight: 500;
}
.loa-table-container .loa-table .date-header {
  font-size: 11.5px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
  height: 33px;
  min-width: 33px !important;
  max-width: 33px !important;
  padding: 0px;
  color: #9e9e9e;
}
.loa-table-container .loa-table .date-header .date-value {
  margin-top: 4px;
}
.loa-table-container .loa-table .driver-value {
  padding-left: 5px;
  padding-right: 5px;
}
.loa-table-container .loa-table .loa-value {
  height: 40px;
}
.loa-table-container .outer-fill-cell {
  padding: 1.5px;
  cursor: pointer;
}
.loa-table-container .outer-fill-cell .inner-loa-fill-cell {
  color: #ffffff;
  border-radius: 3px;
}
.loa-table-container .outer-fill-cell .warning {
  border: 1px solid #F6B93B;
  background-color: #F6B93B;
}
.loa-table-container .outer-fill-cell .error {
  border: 1px solid #EB2F06;
  background-color: #EB2F06;
}
.loa-table-container .outer-fill-cell .inner-empty-fill-cell {
  color: #9e9e9e;
  border-radius: 3px;
}
.loa-table-container .outer-fill-cell .inner-empty-fill-cell:hover {
  border: 0.85px solid #9e9e9e;
}