@import '../../../styles/variables';

.filter-select-month {
    border: 1px solid $neutral-4-color;
    border-radius: 3px;

    @media screen and (max-width: 767px) {
        background: $white;
        width: 100%;
        margin-bottom: 10px;

        .ui.scrolling.dropdown{
            flex-grow: 1;
        }
    }

    .np-button {
        color: $neutral-4-color;
        padding-left: 6px;
        padding-right: 6px;
        height: 100%;
    }

    .np-button-right {
        border-left: 1px solid $neutral-6-color;
    }

    .np-button-left {
        border-right: 1px solid $neutral-6-color;
    }

    .select-month-trigger {
        margin-left: 0px;
        margin-right: 0px;
        min-width: 90px;
    }

    .ui.table td.active {
        background-color: #4bc800 !important;
        color: white !important;
    }

    .year-selector {
        padding: 10px;

        .year-text {
            font-size: 16px;
            margin-left: 10px;
            margin-right: 10px;
            color: $neutral-3-color;
        }
    }
}