@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700');
$font-family: 'Poppins', sans-serif;

// set all z-index together in this file
$z-index-dashboard-top-bar: 1;

$white: #ffffff;

$primary-color: #DE192B;

$secondary-1-color: #154A8F;
$secondary-2-color: #EFF5FB;
$secondary-3-color: #5299E8;

$neutral-1-color: #2E2D2E;
$neutral-2-color: #626262;
$neutral-3-color: #9e9e9e;
$neutral-4-color: #DFDFDF;
$neutral-5-color: #EDEDED;
$neutral-6-color: #f7f7f7;

$default-border: 1px solid #DFDFDF;
$light-border: 1px solid #EDEDED;

$error-color: #EB2F06;
$warning-color: #F6B93B;
$success-color: #4EB064;