@import '../../../styles/variables';

.dd-sticky-header{
    position: sticky;
    top: 0px;
    background: white;
    .dd-header{
        margin-top: 10px;
        margin-bottom: 3px;
        .dd-header-title{
            padding: 0 1.14285714rem;
            color: rgba(0,0,0,.85);
            font-size: .78571429em;
            font-weight: 700;
            text-transform: uppercase;
        }
        .dd-all-button, .dd-none-button{
            color: $success-color;
            margin-right: 10px;
            font-size: 11px;
            text-transform: uppercase;
        }
    }
}
.dd-items {
    margin-bottom: 10px;
    padding: 5px;
    .dd-item {
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        &:hover {
            background: rgba(177, 177, 177, 0.133);
            border-radius: 8px;
        }
    }
}

.filter-search-box {
    height: 30px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    border: $light-border;
    background-color: $white;
    border-radius: 8px;
    color: $neutral-3-color;
    font-size: 12px;
    line-height: 12px;
}