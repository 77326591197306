@import '../../../styles/variables';

.ocf-container {
    .header-text {
        color: $neutral-3-color;
    }

    .add-button {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
    }

    .buttons-container {
        margin-top: 40px;

        .cancel-button {
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                margin-right: inherit;
                margin-top: 15px;
                margin-bottom: 10px;
            }
        }
    }

    .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        margin-bottom: 30px;

        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 20px;
        }
    }

    .singe-package,
    .single-extra {
        margin-bottom: 8px;
    }

    .order-clothing-total {
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px;
    }
}