@import '../../styles/variables';

.rpd-container {
    position: relative;

    .rev-avatar {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .driver-header {
        cursor: pointer;
        background: $neutral-5-color;
        padding-left: 15px;
        padding-right: 15px;

        @media screen and (max-width: 767px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        .driver-name {
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 15px;
            line-height: 23px;
            margin-left: 10px;

            @media screen and (max-width: 767px) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    .per-day-header {
        cursor: pointer;
        background: $neutral-6-color;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(34, 36, 38, .15);

        @media screen and (max-width: 767px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        .right-arrow-icon {
            color: $neutral-3-color;
        }
    }

    .revenues-table {
        border-bottom: 1px solid rgba(34, 36, 38, .1);
    }

    .tm-totals {
        margin-right: 10px;
        font-weight: 600;
    }

    .totals {
        padding-left: 15px;
        font-size: 14px;
        line-height: 21px;
        margin-top: 10px;
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
            padding-left: 10px;
        }
    }

    .ui.table {
        margin-bottom: 0px;
    }

    .ui.table thead th {
        background: none !important;
        font-size: 14px !important;
        line-height: 21px !important;
        font-weight: 500 !important;
        color: $neutral-3-color !important;
    }

    th:first-child {
        padding-left: 15px !important;
    }

    td:first-child {
        padding-left: 15px !important;
    }

    .expand-button {
        color: $neutral-3-color;
    }

    .empty-driver {
        height: 5px;
    }

    .mobile-single-revenue {
        padding: 10px;

        .date {
            margin-bottom: 5px;
        }

        .info {
            font-size: 13px;
            line-height: 20px;
        }
    }
}