@import '../../../styles/variables';

.form-dialog{
    display: flex;
    flex-direction: column;
    padding: 40.5px 70px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: $white;
    box-shadow: 2px 2px 8px 0 $neutral-4-color;
    max-width: 600px;
}