.password-input-field{
    input{
        width: 100%;
    }
    .icon-button{
        position: relative;
        margin-right: 13px;
        margin-left: auto;
        float: right;
        margin-top: -34px;
        color: gray;
    }
}