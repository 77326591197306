@import '../../styles/variables';

.full-page-sheet {
    background: $secondary-2-color;
    width: 100%;
    height: 100%;

    .login-header {
        padding: 20px 40px;

        .contact-us-button {
            margin-right: 10px;
        }
    }

    .login-body {
        margin-top: 30px;
        flex-grow: 1;

        .welcome-text {
            font-size: 30px;
            font-weight: bold;
            line-height: 46px;
        }

        .sub-welcome-text {
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 40px;
        }

        .form-dialog {
            width: 600px;
        }

        .forgot-password-button {
            margin-top: 4px;
            margin-bottom: 20px;
        }
    }

    .mobile-no-account {
        margin-top: 30px;
        margin-bottom: 10px;

        span {
            margin-right: 8px;
        }
    }

    @media screen and (max-width: 767px) {
        .login-header {
            padding-bottom: 0px;
        }

        .login-body {
            .welcome-text {
                font-size: 24px;
            }

            .sub-welcome-text {
                font-size: 14px;
                padding-left: 50px;
                padding-right: 50px;
            }

            .form-dialog {
                padding: 0px;
                padding-left: 26px;
                padding-right: 26px;
                background: $secondary-2-color;
                border: none;
                box-shadow: none;
                width: 100%;
            }
        }

        .login-footer {
            padding-left: 26px;
            padding-right: 26px;
        }
    }
}