.fp-body {
    margin-top: 30px;
    flex-grow: 1;

    @media screen and (max-width: 767px) {
        padding-left: 10px;
        padding-right: 10px;  
    }

    .welcome-text {
        font-size: 30px;
        font-weight: bold;
        line-height: 46px;
    }

    .sub-welcome-text {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 40px;
        text-align: center;
    }

    .fp-content {
        white-space: pre-line;
        font-size: 18px;
        line-height: 35px;

        @media screen and (max-width: 767px) {
            font-size: 14px;          
        }
    }

    .fp-mobile-go-back-button {
        margin-top: 20px;
    }
}

.fp-header {
    padding: 20px 40px;
}