@import '../styles/variables';

.notifications-dropdown {
    background: $secondary-2-color !important;
    height: 28px;
    width: 28px;
    border: 2px solid $neutral-1-color;
    color: $neutral-1-color;
    border-radius: 3px;
    background-color: $white;
    text-align: center;
    line-height: 24px;

    .ui.dropdown {
        width: 100%;
        text-align: center;

        i {
            display: none;
        }
    }

    .new-notifications-label {
        position: absolute;
        margin-left: 13px;
        margin-top: -6px;
    }
}