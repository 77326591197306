@import '../../styles/variables';

.notifications-card{
    padding: 0px !important;
    .notifications-header{
        padding: 15px;
        font-size: 18px;
        font-weight: 600;
        @media screen and (max-width: 767px) {
            padding: 10px;
        }
    }
    .mobile-single-notification{
        &.unread{
            background: $neutral-6-color;
            font-weight: 600;
        }
        &.high-priority{
            color: $error-color;
        }
        .info{
            font-size: 13px;
            line-height: 20px;
        }
        .info-italic{
            font-size: 13px;
            line-height: 20px;
            font-style: italic;
        }
    }
}