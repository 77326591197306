@import '../../styles/variables';

.revenues-summary-container {
    .rs-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 25px;
        }
    }

    .label {
        color: $neutral-3-color;
        font-size: 16px;
        line-height: 25px;
        margin-top: 20px;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    .info {
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;

        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 23px;
        }
    }

    .last-month-text {
        margin-left: 10px;
    }

    .last-month-value {
        margin-left: 5px;
        margin-right: 5px;
    }

    .middle {
        margin-left: 24px;
        margin-right: 24px;
    }
}