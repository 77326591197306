@import '../../../styles/variables';

.icon-button{
    &:disabled{
        cursor: not-allowed;
    }
    &:not(:disabled){
        cursor: pointer;
    }
    // cursor: pointer;
    padding: 0px;
    background: transparent;
    border: none;

    &:focus{
        outline: none;
    }
    &.gray{
        color: $neutral-4-color;
    }
    &.large{
        font-size: 18px;
    }
    &.very-large{
        font-size: 24px;
    }
    &.success{
        color: $success-color;
    }
    &.warning{
        color: $warning-color;
    }
    &.error{
        color: $error-color;
    }
}