.toggle-checkbox {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    outline: none;
    input{
        visibility: hidden;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }
    
    input:checked+.slider {
        background-color: #4EB064;
    }
    
    input:focus+.slider {
        box-shadow: 0 0 1px #4EB064;
    }
    
    input:checked+.slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }
}