@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
.ui.card {
  padding: 15px;
  margin: 0;
  width: auto;
  box-shadow: 2px 2px 8px 0 rgba(151, 151, 151, 0.6);
  background-color: #ffffff;
  border-radius: 2px;
  border: none;
}
@media screen and (max-width: 767px) {
  .ui.card {
    padding: 10px;
  }
}

.ui.modal {
  position: relative;
}
.ui.modal .content {
  height: 100%;
  padding: 28px 50px 31px 50px;
}

.ui.table {
  margin-top: 0;
  border: none;
  border-radius: 0;
}

.ui.table tfoot th {
  background: white;
}

.ui.table td, .ui.table thead th, .ui.table tfoot th {
  padding-left: 15px;
  padding-right: 0px;
}
.ui.table td.no-padding, .ui.table thead th.no-padding, .ui.table tfoot th.no-padding {
  padding: 0px;
}

.ui.table td:last-child, .ui.table thead th:last-child, .ui.table tfoot th:last-child {
  padding-right: 15px;
}

.ui.divider.no-margin {
  margin: 0px;
}

.ui.table thead th {
  font-weight: 500;
  background: #EDEDED !important;
  border-left: none !important;
}
.ui.table thead th:hover {
  background: #EDEDED;
}
.ui.table thead th.no-sort {
  cursor: default;
}

.ui.scrolling.dropdown .menu.dd-menu {
  min-width: 250px !important;
}
.ui.scrolling.dropdown .menu.no-max-height {
  max-height: none !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.65);
}

.Toastify__toast {
  font-family: "Poppins", sans-serif !important;
  box-shadow: 2px 2px 8px 0 #9e9e9e !important;
  background-color: #9e9e9e !important;
  border-radius: 5px !important;
  padding: 28px 20px !important;
}

.Toastify__toast--default {
  background-color: #9e9e9e !important;
}

.Toastify__toast--success {
  background-color: #4EB064 !important;
}

.Toastify__toast--warning {
  background-color: #F6B93B !important;
}

.Toastify__toast--error {
  background-color: #EB2F06 !important;
}

@media only screen and (max-width: 767px) {
  .ui.modal {
    width: 100%;
    margin: 0;
  }
  .ui.scrolling.dropdown .menu {
    width: 100% !important;
    max-height: inherit !important;
  }
}
@media screen and (min-width: 768px) {
  .ui.modal .close.icon {
    display: none;
  }
}

.df-row {
  display: flex;
  flex-direction: row;
}

.df-row-ac {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .df-col-if-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .df-col-ac-if-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .hide-if-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .df-col-reverse-if-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.df-row-ab {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.df-row-afs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.df-row-afs-left {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}

.df-row-afs-right {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
}

.df-col-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.df-col-ab {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.df-col {
  display: flex;
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.jc-e {
  justify-content: flex-end;
}

.jc-se {
  justify-content: space-evenly;
}

.sticky-at-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky-at-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.stretch-ver {
  height: 100%;
}

.stretch-hor {
  width: 100%;
}

.tac {
  text-align: center;
}

.fg1 {
  flex-grow: 1;
}

.font-bold {
  font-weight: 600;
}

.font-error {
  color: #EB2F06;
}

.font-success {
  color: #4EB064;
}

.pos-rel {
  position: relative;
}

.ml-10px {
  margin-left: 10px;
}

.mr-5px {
  margin-right: 5px;
}

.mr-20px {
  margin-right: 20px;
}

.z-index-1 {
  z-index: 1;
}

.overflow-auto {
  overflow: auto;
}

.padding-10px {
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .jc-c-if-mobile {
    justify-content: center;
  }
}

html {
  display: flex;
  width: 100%;
}
html body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

html, body, #root {
  height: 100%;
  overflow: auto;
}

input, textarea, select, button {
  font-family: "Poppins", sans-serif;
}
.toast-notification {
  font-family: "Poppins", sans-serif;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
.primary-button {
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0 rgba(158, 158, 158, 0.5);
  text-align: center;
  text-decoration: none;
  border: 1px solid #DE192B;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  white-space: nowrap;
}
.primary-button .loader-wrapper {
  position: absolute;
  width: 100%;
}
.primary-button .loader-children {
  visibility: hidden;
}
.primary-button.stretch {
  width: 100%;
}
.primary-button:not(.small) {
  font-size: 20px;
  line-height: 30px;
  padding: 15px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .primary-button:not(.small) {
    font-size: 16px;
    line-height: 25px;
  }
}
.primary-button.small {
  font-size: 14px;
  line-height: 21px;
  padding: 5px;
  font-weight: 400;
}
.primary-button:not(.outline) {
  background-color: #DE192B;
  color: #ffffff;
}
.primary-button.outline {
  color: #2E2D2E;
  border: 1px solid #DFDFDF;
  background: transparent;
}
.primary-button:hover:not(.drop-down disabled) {
  opacity: 0.7;
}
.primary-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.primary-button:focus {
  outline: none;
}
.primary-button.rectangle-padding {
  padding: 10px 30px;
}
.text-link-button {
  color: #2E2D2E;
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  font-size: 16px;
  line-height: 25px;
}
.text-link-button:hover {
  opacity: 0.8;
}
.text-link-button.tlb-gray {
  color: #9e9e9e;
}
.text-link-button.tlb-underline {
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .text-link-button {
    font-size: 14px;
    line-height: 21px;
  }
}
.text-link-button.tlb-small {
  font-size: 14px;
  line-height: 21px;
}
.text-link-button.tlb-extra-small {
  font-size: 12px;
  line-height: 16px;
}
.text-link-button:focus {
  outline: none;
}
.form-dialog {
  display: flex;
  flex-direction: column;
  padding: 40.5px 70px;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 2px 2px 8px 0 #DFDFDF;
  max-width: 600px;
}
.icon-button {
  padding: 0px;
  background: transparent;
  border: none;
}
.icon-button:disabled {
  cursor: not-allowed;
}
.icon-button:not(:disabled) {
  cursor: pointer;
}
.icon-button:focus {
  outline: none;
}
.icon-button.gray {
  color: #DFDFDF;
}
.icon-button.large {
  font-size: 18px;
}
.icon-button.very-large {
  font-size: 24px;
}
.icon-button.success {
  color: #4EB064;
}
.icon-button.warning {
  color: #F6B93B;
}
.icon-button.error {
  color: #EB2F06;
}
.field-group {
  display: flex;
  flex-direction: column;
}
.field-group:not(.no-margin) {
  margin-bottom: 20px;
}
.field-group label {
  line-height: 18px;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .field-group label {
    font-size: 14px;
    line-height: 18px;
  }
}
.field-group input::-webkit-input-placeholder {
  font-size: 14px;
}
.field-group input::placeholder {
  font-size: 14px;
}
.field-group input,
.field-group select {
  box-sizing: border-box;
  border: 1px solid #DFDFDF;
  border-radius: 2px;
  font-size: 18px;
  line-height: 18px;
  padding: 11.5px 8.5px;
  color: #2E2D2E;
}
@media screen and (max-width: 767px) {
  .field-group input,
.field-group select {
    font-size: 16px;
    line-height: 16px;
  }
}
.field-group .error-message {
  font-size: 14px;
  color: #EB2F06;
  padding-top: 5px;
}
.field-group .label-container {
  margin-bottom: 3px;
}
.field-group .label-container .optional-tag {
  color: #9e9e9e;
  font-size: 14px;
}
.password-input-field input {
  width: 100%;
}
.password-input-field .icon-button {
  position: relative;
  margin-right: 13px;
  margin-left: auto;
  float: right;
  margin-top: -34px;
  color: gray;
}
.full-page-sheet {
  background: #EFF5FB;
  width: 100%;
  height: 100%;
}
.full-page-sheet .login-header {
  padding: 20px 40px;
}
.full-page-sheet .login-header .contact-us-button {
  margin-right: 10px;
}
.full-page-sheet .login-body {
  margin-top: 30px;
  flex-grow: 1;
}
.full-page-sheet .login-body .welcome-text {
  font-size: 30px;
  font-weight: bold;
  line-height: 46px;
}
.full-page-sheet .login-body .sub-welcome-text {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 40px;
}
.full-page-sheet .login-body .form-dialog {
  width: 600px;
}
.full-page-sheet .login-body .forgot-password-button {
  margin-top: 4px;
  margin-bottom: 20px;
}
.full-page-sheet .mobile-no-account {
  margin-top: 30px;
  margin-bottom: 10px;
}
.full-page-sheet .mobile-no-account span {
  margin-right: 8px;
}
@media screen and (max-width: 767px) {
  .full-page-sheet .login-header {
    padding-bottom: 0px;
  }
  .full-page-sheet .login-body .welcome-text {
    font-size: 24px;
  }
  .full-page-sheet .login-body .sub-welcome-text {
    font-size: 14px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .full-page-sheet .login-body .form-dialog {
    padding: 0px;
    padding-left: 26px;
    padding-right: 26px;
    background: #EFF5FB;
    border: none;
    box-shadow: none;
    width: 100%;
  }
  .full-page-sheet .login-footer {
    padding-left: 26px;
    padding-right: 26px;
  }
}
.search-box input {
  border: none;
  flex-grow: 1;
}
.search-box input:focus {
  outline: none;
}
.search-box.large input {
  font-size: 16px;
  line-height: 16px;
}
.search-box:not(.large) input {
  font-size: 12px;
  line-height: 12px;
}
.search-box .search-button {
  color: #9e9e9e;
  margin-right: 10px;
  margin-left: 10px;
}
.search-box .clear-button {
  color: #9e9e9e;
  margin-right: 10px;
}
.dd-custom .search-box-container {
  background: #ffffff;
}
.dd-custom .dd-search-box {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dd-custom .dd-trigger {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2px;
}
.dd-custom .dd-trigger:not(.is-open) {
  border: 1px solid #EDEDED;
}
.dd-custom .dd-trigger.is-open {
  border-top: 1px solid #EDEDED;
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
}
.dd-custom .dd-trigger input {
  padding: 10px;
  border: none;
  flex-grow: 1;
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
.dd-custom .dd-trigger input:focus {
  outline: none;
}
@media screen and (max-width: 767px) {
  .dd-custom .dd-trigger input {
    font-size: 16px;
  }
}
.dd-custom .dd-trigger .label-text {
  flex-grow: 1;
  color: #9e9e9e;
}
.dd-custom .dd-trigger .label-text:not(.small) {
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
@media screen and (max-width: 767px) {
  .dd-custom .dd-trigger .label-text:not(.small) {
    font-size: 16px;
  }
}
.dd-custom .dd-trigger .label-text.small {
  padding: 10px;
}
.dd-custom .dd-trigger .ddt-icon {
  color: #9e9e9e;
  padding: 10px 10px 10px 0px;
}
.dd-custom .dd-trigger.selected:not(.format-selected) .label-text {
  color: #2E2D2E;
}
.dd-custom .dd-trigger.format-selected {
  background-color: #DE192B;
}
.dd-custom .dd-trigger.format-selected .label-text {
  color: #ffffff;
}
.dd-custom .dd-trigger.format-selected .ddt-icon {
  color: #ffffff;
}
.dd-custom .dd-trigger .add-button {
  margin-left: 10px;
}
.dd-custom .menu.transition.visible {
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  border-top: none;
  border-radius: 0px;
  box-shadow: none;
}

.simple-list-item {
  font-size: 16px;
  line-height: 18px;
  padding: 8px 10px 8px 10px;
  color: #2E2D2E;
  cursor: pointer;
}
.simple-list-item:hover {
  background: rgba(177, 177, 177, 0.133);
}
.simple-list-item.selected {
  color: #ffffff;
  background: #DE192B;
}
.outdated-application-header {
  background: #DFDFDF;
  padding: 10px;
  border-bottom: 1px solid #9e9e9e;
}
.outdated-application-header .app-update-button {
  margin-left: 10px;
}
.default-modal {
  max-width: 600px;
}
.confirmation-modal .confirmation-modal-inner {
  flex-grow: 1;
}
.confirmation-modal .mc-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .mc-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.confirmation-modal .mc-description {
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .mc-description {
    font-size: 14px;
    line-height: 22px;
  }
}
.confirmation-modal .cancel-button {
  margin-right: 20px;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .cancel-button {
    margin-right: inherit;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.information-modal .mc-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
}
@media screen and (max-width: 767px) {
  .information-modal .mc-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.information-modal .mc-description {
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (max-width: 767px) {
  .information-modal .mc-description {
    font-size: 14px;
    line-height: 22px;
  }
}
.dashboard-search-box {
  border: 1px solid #EDEDED;
  background-color: #ffffff;
  box-shadow: 2px 2px 8px 0 #DFDFDF;
  border-radius: 2px;
  padding: 10.5px 14.5px;
  color: #9e9e9e;
  font-size: 12px;
  line-height: 12px;
}
@media screen and (max-width: 767px) {
  .dashboard-search-box {
    border: none;
    background-color: #f7f7f7;
    width: 100%;
    padding-left: 5px;
  }
  .dashboard-search-box input {
    background-color: #f7f7f7;
  }
}

@media screen and (max-width: 767px) {
  .go-back-button {
    background-color: #f7f7f7;
    color: #9e9e9e;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    border-bottom: 1px solid #DFDFDF;
    font-size: 12px;
    line-height: 12px;
  }
}
.notifications-dropdown {
  background: #EFF5FB !important;
  height: 28px;
  width: 28px;
  border: 2px solid #2E2D2E;
  color: #2E2D2E;
  border-radius: 3px;
  background-color: #ffffff;
  text-align: center;
  line-height: 24px;
}
.notifications-dropdown .ui.dropdown {
  width: 100%;
  text-align: center;
}
.notifications-dropdown .ui.dropdown i {
  display: none;
}
.notifications-dropdown .new-notifications-label {
  position: absolute;
  margin-left: 13px;
  margin-top: -6px;
}
.information-icon-button {
  font-size: 18px;
  color: #DFDFDF;
}
.information-icon-button.with-margin {
  margin-left: 10px;
}
.base-table td.can-select {
  cursor: pointer;
}
.rpd-container {
  position: relative;
}
.rpd-container .rev-avatar {
  margin-top: 5px;
  margin-bottom: 5px;
}
.rpd-container .driver-header {
  cursor: pointer;
  background: #EDEDED;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 767px) {
  .rpd-container .driver-header {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.rpd-container .driver-header .driver-name {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  line-height: 23px;
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .rpd-container .driver-header .driver-name {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.rpd-container .per-day-header {
  cursor: pointer;
  background: #f7f7f7;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
@media screen and (max-width: 767px) {
  .rpd-container .per-day-header {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.rpd-container .per-day-header .right-arrow-icon {
  color: #9e9e9e;
}
.rpd-container .revenues-table {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.rpd-container .tm-totals {
  margin-right: 10px;
  font-weight: 600;
}
.rpd-container .totals {
  padding-left: 15px;
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .rpd-container .totals {
    padding-left: 10px;
  }
}
.rpd-container .ui.table {
  margin-bottom: 0px;
}
.rpd-container .ui.table thead th {
  background: none !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 500 !important;
  color: #9e9e9e !important;
}
.rpd-container th:first-child {
  padding-left: 15px !important;
}
.rpd-container td:first-child {
  padding-left: 15px !important;
}
.rpd-container .expand-button {
  color: #9e9e9e;
}
.rpd-container .empty-driver {
  height: 5px;
}
.rpd-container .mobile-single-revenue {
  padding: 10px;
}
.rpd-container .mobile-single-revenue .date {
  margin-bottom: 5px;
}
.rpd-container .mobile-single-revenue .info {
  font-size: 13px;
  line-height: 20px;
}
.revenues-summary-container .rs-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .revenues-summary-container .rs-title {
    font-size: 20px;
    line-height: 25px;
  }
}
.revenues-summary-container .label {
  color: #9e9e9e;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .revenues-summary-container .label {
    font-size: 14px;
    line-height: 21px;
  }
}
.revenues-summary-container .info {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .revenues-summary-container .info {
    font-size: 16px;
    line-height: 23px;
  }
}
.revenues-summary-container .last-month-text {
  margin-left: 10px;
}
.revenues-summary-container .last-month-value {
  margin-left: 5px;
  margin-right: 5px;
}
.revenues-summary-container .middle {
  margin-left: 24px;
  margin-right: 24px;
}
.dashboard-container {
  height: 100%;
}
@media screen and (max-width: 768px) {
  .dashboard-container .info-card {
    margin-right: inherit;
    margin-top: 10px !important;
  }
}
.dashboard-container .confirmed-transports-card {
  padding: 0px;
  margin-top: 20px;
}
.dashboard-container .confirmed-transports-card .transports-header {
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .dashboard-container .confirmed-transports-card .transports-header {
    padding: 10px;
  }
  .dashboard-container .confirmed-transports-card .transports-header .totals-button {
    margin-right: 10px;
  }
}
.dashboard-container .confirmed-transports-card .new-transport-link {
  margin-right: 15px;
}
.dashboard-container .confirmed-transports-card .revenues-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-right: 5px;
}
@media screen and (max-width: 767px) {
  .dashboard-container .confirmed-transports-card .revenues-title {
    font-size: 16px;
    line-height: 21px;
  }
}
.dashboard-container .confirmed-transports-card .no-revenues {
  padding: 15px;
}
.unread-notification {
  cursor: pointer;
}
.unread-notification .un-title {
  font-size: 15px;
  line-height: 23px;
}
@media screen and (max-width: 767px) {
  .unread-notification .un-title {
    font-size: 13px;
    line-height: 20px;
  }
}
.unread-notification .un-more-info {
  font-size: 15px;
  line-height: 23px;
}
@media screen and (max-width: 767px) {
  .unread-notification .un-more-info {
    font-size: 12px;
    line-height: 18px;
  }
}
.info-card {
  flex-grow: 1;
}
.info-card .title {
  font-size: 18px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .info-card .title {
    font-size: 16px;
  }
}
.info-card .date {
  margin-top: 20px;
  font-size: 15px;
  line-height: 23px;
}
@media screen and (max-width: 767px) {
  .info-card .date {
    font-size: 13px;
    margin-top: 0px;
    color: #626262;
  }
}
.filter-trigger {
  padding: 6px;
  margin-right: 6px;
}
.filter-trigger:hover {
  background: rgba(177, 177, 177, 0.133);
  border-radius: 8px;
}
.filter-trigger .ft-icon {
  background: #DFDFDF;
  margin-right: 7px;
  color: white;
  border-radius: 4px;
  height: 18px;
  width: 18px;
  font-size: 10px;
}
.filter-trigger .ft-icon.ft-icon-selected {
  background: #4EB064;
}
.filter-trigger .ft-label {
  position: absolute;
  background: #EB2F06;
  text-align: center;
  width: 16px;
  height: 16px;
  font-size: 11px;
  border-radius: 100px;
  left: 0;
  top: 0;
  line-height: 16px;
}

.dd-menu {
  min-width: 250px !important;
  max-height: none;
}

@media screen and (max-width: 767px) {
  .top-level-div {
    width: 100%;
  }
}
.filter-select-month {
  border: 1px solid #DFDFDF;
  border-radius: 3px;
}
@media screen and (max-width: 767px) {
  .filter-select-month {
    background: #ffffff;
    width: 100%;
    margin-bottom: 10px;
  }
  .filter-select-month .ui.scrolling.dropdown {
    flex-grow: 1;
  }
}
.filter-select-month .np-button {
  color: #DFDFDF;
  padding-left: 6px;
  padding-right: 6px;
  height: 100%;
}
.filter-select-month .np-button-right {
  border-left: 1px solid #f7f7f7;
}
.filter-select-month .np-button-left {
  border-right: 1px solid #f7f7f7;
}
.filter-select-month .select-month-trigger {
  margin-left: 0px;
  margin-right: 0px;
  min-width: 90px;
}
.filter-select-month .ui.table td.active {
  background-color: #4bc800 !important;
  color: white !important;
}
.filter-select-month .year-selector {
  padding: 10px;
}
.filter-select-month .year-selector .year-text {
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  color: #9e9e9e;
}
.filter-bar {
  padding-left: 15px;
  height: 50px;
}
.filter-bar .filter-on-text {
  margin-right: 12px;
}
.filter-bar .filter-clear-container {
  cursor: pointer;
  padding-right: 15px;
  color: #9e9e9e;
}
@media screen and (max-width: 767px) {
  .filter-bar .filter-clear-container {
    padding-right: 0px;
  }
}
.filter-bar .filter-clear-container .filter-clear-icon {
  margin-top: 2px;
  margin-right: 5px;
  color: #9e9e9e;
}
@media screen and (max-width: 767px) {
  .filter-bar {
    height: auto;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    justify-content: space-between;
    align-items: baseline;
  }
}
.custom-list-item {
  font-size: 14px;
  cursor: pointer;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
  border-radius: 8px;
}
.custom-list-item:hover {
  background: rgba(177, 177, 177, 0.133);
}
.custom-list-item.cli-checked {
  background: rgba(157, 197, 132, 0.2);
}
.custom-list-item .cli-label {
  margin-left: 5px;
}
.custom-list-item .cli-label.ccb-checked {
  color: #4EB064;
}
.custom-list-item .cli-icon {
  width: 14px;
  height: 14px;
  border-radius: 4px;
}
.custom-list-item .cli-icon .svg-icon {
  font-size: 9px;
}
.custom-list-item .cli-icon.cli-checked {
  background: #4EB064;
  color: white;
}
.custom-list-item .cli-icon:not(.cli-checked) {
  border: 0.5px solid #DFDFDF;
}
.dd-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
}
.dd-sticky-header .dd-header {
  margin-top: 10px;
  margin-bottom: 3px;
}
.dd-sticky-header .dd-header .dd-header-title {
  padding: 0 1.14285714rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.78571429em;
  font-weight: 700;
  text-transform: uppercase;
}
.dd-sticky-header .dd-header .dd-all-button, .dd-sticky-header .dd-header .dd-none-button {
  color: #4EB064;
  margin-right: 10px;
  font-size: 11px;
  text-transform: uppercase;
}

.dd-items {
  margin-bottom: 10px;
  padding: 5px;
}
.dd-items .dd-item {
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
}
.dd-items .dd-item:hover {
  background: rgba(177, 177, 177, 0.133);
  border-radius: 8px;
}

.filter-search-box {
  height: 30px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #EDEDED;
  background-color: #ffffff;
  border-radius: 8px;
  color: #9e9e9e;
  font-size: 12px;
  line-height: 12px;
}
.revenues-card {
  padding: 0px !important;
}
.revenues-card .revenues-header {
  padding-top: 15px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 767px) {
  .revenues-card .revenues-header {
    padding: 10px;
  }
}
.revenues-card .revenues-header .totals {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .revenues-card .revenues-header .totals {
    margin-left: inherit;
  }
}
.revenues-card .no-revenues {
  padding: 15px;
}
.base-table td.can-select {
  cursor: pointer;
}

.loa-table-container .ui.table thead th {
  background: none;
}
.loa-table-container .ui.table td {
  padding: 0px;
}
.loa-table-container .loa-table .driver-name {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
}
.loa-table-container .loa-table .driver-header {
  font-size: 14px;
  font-weight: 500;
}
.loa-table-container .loa-table .date-header {
  font-size: 11.5px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
  height: 33px;
  min-width: 33px !important;
  max-width: 33px !important;
  padding: 0px;
  color: #9e9e9e;
}
.loa-table-container .loa-table .date-header .date-value {
  margin-top: 4px;
}
.loa-table-container .loa-table .driver-value {
  padding-left: 5px;
  padding-right: 5px;
}
.loa-table-container .loa-table .loa-value {
  height: 40px;
}
.loa-table-container .outer-fill-cell {
  padding: 1.5px;
  cursor: pointer;
}
.loa-table-container .outer-fill-cell .inner-loa-fill-cell {
  color: #ffffff;
  border-radius: 3px;
}
.loa-table-container .outer-fill-cell .warning {
  border: 1px solid #F6B93B;
  background-color: #F6B93B;
}
.loa-table-container .outer-fill-cell .error {
  border: 1px solid #EB2F06;
  background-color: #EB2F06;
}
.loa-table-container .outer-fill-cell .inner-empty-fill-cell {
  color: #9e9e9e;
  border-radius: 3px;
}
.loa-table-container .outer-fill-cell .inner-empty-fill-cell:hover {
  border: 0.85px solid #9e9e9e;
}
.toggle-checkbox {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  outline: none;
}
.toggle-checkbox input {
  visibility: hidden;
}
.toggle-checkbox .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.toggle-checkbox .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.toggle-checkbox input:checked + .slider {
  background-color: #4EB064;
}
.toggle-checkbox input:focus + .slider {
  box-shadow: 0 0 1px #4EB064;
}
.toggle-checkbox input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}

.field-date-input .ui.input {
  width: 100%;
}
.field-time-input .ui.input {
  width: 100%;
}
.loa-form-container .loa-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .loa-form-container .loa-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
}
.loa-form-container .is-whole-day-option {
  margin-bottom: 20px;
}
.loa-form-container .is-whole-day-option .is-whole-day-text {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .loa-form-container .is-whole-day-option .is-whole-day-text {
    font-size: 14px;
    line-height: 21px;
  }
}
.loa-form-container .cancel-button {
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .loa-form-container .cancel-button {
    margin-right: inherit;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.loa-form-container .buttons-container {
  margin-top: 10px;
}
.loa-detail-container .loa-detail-inner {
  flex-grow: 1;
}
.loa-detail-container .loa-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .loa-detail-container .loa-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
}
.loa-detail-container .label {
  color: #9e9e9e;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .loa-detail-container .label {
    font-size: 14px;
    line-height: 21px;
  }
}
.loa-detail-container .info {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .loa-detail-container .info {
    font-size: 16px;
    line-height: 25px;
  }
}
.loa-detail-container .cancel-button {
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .loa-detail-container .cancel-button {
    margin-right: inherit;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.loa-detail-container .delete-loa-button {
  margin-left: 10px;
}
.loa-card {
  padding: 0px !important;
}
.loa-card .loa-header {
  padding: 10px;
  padding-bottom: 0px;
}
@media screen and (max-width: 767px) {
  .loa-card .loa-header {
    padding-bottom: 10px;
  }
}
.loa-card .loa-header .export-button {
  margin-left: 10px;
}
.loa-card .loa-header .loa-month-sel {
  margin-left: 10px;
}
.loa-card .mobile-loa-grouped-by-driver {
  padding: 10px;
}
.loa-card .mobile-loa-grouped-by-driver .d-header {
  margin-bottom: 5px;
}
.loa-card .mobile-loa-grouped-by-driver .d-header .driver-name {
  margin-left: 5px;
}
.loa-card .mobile-loa-grouped-by-driver .mobile-loa {
  margin-top: 5px;
  color: #9e9e9e;
}
.dfi-container .dfi-selected-value {
  padding: 10px;
  text-align: center;
}
.dfi-container .dfi-enum-options {
  padding: 5px;
  border-right: 1px solid #DFDFDF;
}
.dfi-container table .suicr-dates-range-view-header tr:first-child {
  display: none;
}
.dfi-container table .suicr-dates-range-view-header tr:nth-child(2) th {
  color: rgb(123, 123, 123);
  padding-top: 8px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.dfi-container table .suicr-dates-range-view-header tr:last-child th {
  font-size: 11px;
  color: rgb(123, 123, 123);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0px;
  padding-right: 0px;
}
.dfi-container table tbody td {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.dfi-container .ui.table td.active {
  background-color: #4bc800 !important;
  color: white !important;
}
.bt-footer .bt-footer-page-info {
  color: #777777;
  font-size: 14px;
  line-height: 21px;
}
.bt-footer .bt-previous-button {
  margin-right: 10px;
}
.notifications-card {
  padding: 0px !important;
}
.notifications-card .notifications-header {
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .notifications-card .notifications-header {
    padding: 10px;
  }
}
.notifications-card .mobile-single-notification.unread {
  background: #f7f7f7;
  font-weight: 600;
}
.notifications-card .mobile-single-notification.high-priority {
  color: #EB2F06;
}
.notifications-card .mobile-single-notification .info {
  font-size: 13px;
  line-height: 20px;
}
.notifications-card .mobile-single-notification .info-italic {
  font-size: 13px;
  line-height: 20px;
  font-style: italic;
}
.text-area-field {
  width: 100%;
  resize: none;
  height: 150px;
  padding: 14.5px 8.5px;
  font-size: 18px;
  line-height: 24px;
  color: #2E2D2E;
  border: 1px solid #DFDFDF;
}
.dd-container .dd-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .dd-container .dd-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
}
.dd-container .dd-label-file-number {
  font-size: 16px;
  line-height: 25px;
  color: #626262;
}
@media screen and (max-width: 767px) {
  .dd-container .dd-label-file-number {
    font-size: 14px;
    line-height: 21px;
  }
}
.dd-container .dd-file-number {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 14px;
}
@media screen and (max-width: 767px) {
  .dd-container .dd-file-number {
    font-size: 16px;
    line-height: 25px;
  }
}
.dd-container .cancel-button {
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .dd-container .cancel-button {
    margin-right: inherit;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.revenue-detail-card .label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.revenue-detail-card .label:not(.without-margin) {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .revenue-detail-card .label {
    font-size: 12px;
    line-height: 18px;
  }
}
.revenue-detail-card .info {
  font-size: 16px;
  line-height: 25px;
}
@media screen and (max-width: 767px) {
  .revenue-detail-card .info {
    font-size: 14px;
    line-height: 21px;
  }
}
.revenue-detail-card .single-price-calc {
  margin-right: 20px;
}
.revenue-detail-card .dispute-button,
.revenue-detail-card .download-button {
  margin-left: 10px;
}
.revenue-detail-card .status {
  padding: 7px 15px 7px 15px;
  border-radius: 100px;
}
.revenue-detail-card .status.confirmed {
  color: white;
  background-color: #4EB064;
}
.revenue-detail-card .status:not(.confirmed) {
  background-color: #DFDFDF;
}
.revenue-detail-card .status .status-text {
  margin-left: 5px;
  line-height: 21px;
  white-space: nowrap;
}
.revenue-detail-card .assignment-container {
  margin-right: 10px;
}
.revenue-detail-card .status-and-buttons-container {
  margin-bottom: 15px;
}
.stop-view {
  font-size: 16px;
  line-height: 25px;
}
@media screen and (max-width: 767px) {
  .stop-view {
    font-size: 14px;
    line-height: 21px;
  }
}
.stop-view .stop-button {
  text-align: right;
}
.stop-view .label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.stop-view .label:not(.without-margin) {
  margin-top: 20px;
}
.full-screen-loader {
  position: absolute;
  z-index: 2;
  background-color: rgba(239, 245, 251, 0.85);
}
.ocf-container .header-text {
  color: #9e9e9e;
}
.ocf-container .add-button {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}
.ocf-container .buttons-container {
  margin-top: 40px;
}
.ocf-container .buttons-container .cancel-button {
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .ocf-container .buttons-container .cancel-button {
    margin-right: inherit;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.ocf-container .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .ocf-container .title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
}
.ocf-container .singe-package,
.ocf-container .single-extra {
  margin-bottom: 8px;
}
.ocf-container .order-clothing-total {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}
.fd-container .fd-inner {
  flex-grow: 1;
}
.fd-container .fd-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .fd-container .fd-title {
    font-size: 20px;
    line-height: 25px;
  }
}
.fd-container .label {
  color: #9e9e9e;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .fd-container .label {
    font-size: 14px;
    line-height: 21px;
  }
}
.fd-container .info {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .fd-container .info {
    font-size: 16px;
    line-height: 23px;
  }
}
.forms-card {
  padding: 0px !important;
}
.forms-card .forms-header {
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .forms-card .forms-header {
    padding: 10px;
  }
}
.forms-card .mobile-single-form {
  padding: 10px;
}
.forms-card .mobile-single-form .date {
  margin-bottom: 5px;
}
.forms-card .mobile-single-form .info {
  font-size: 13px;
  line-height: 20px;
}
.mobile-notifications {
  background: #ffffff;
  height: 28px;
  width: 28px;
  border: 2px solid #2E2D2E;
  border-radius: 3px;
  text-align: center;
  line-height: 23px;
  color: #2E2D2E;
}
.mobile-notifications .new-notifications-label {
  position: absolute;
  margin-left: 3px !important;
  margin-top: -6px;
}
.mobile-footer-container {
  padding: 10px;
  justify-content: space-evenly;
}
.mobile-footer-container span {
  font-size: 10px;
}
.mobile-footer-container svg {
  height: 30px;
}
.mobile-footer-container .nav-link {
  color: #9e9e9e;
}
.mobile-footer-container .active .nav-link {
  color: #2E2D2E;
}
.mobile-footer-container .active .regular-icon {
  display: none;
}
.mobile-footer-container .nav-link:not(.active) .selected-icon {
  display: none;
}
.sp-card {
  padding: 0px !important;
}
.sp-card .sp-header {
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .sp-card .sp-header {
    padding: 10px;
  }
}
.sp-card .mobile-single-sp {
  padding: 10px;
}
.sp-card .mobile-single-sp .date {
  margin-bottom: 5px;
}
.sp-card .mobile-single-sp .info {
  font-size: 13px;
  line-height: 20px;
}
.language-label {
  margin-bottom: 5px;
}
.container {
  height: 100%;
}
.container .body-wrapper {
  overflow: auto;
  width: 100%;
}

.container .side-nav {
  background-color: #EFF5FB;
  padding: 20px 35px 20px 15px;
}
.container .nav-links {
  margin-top: 6px;
}
.container .nav-links a {
  cursor: pointer;
  color: #626262;
  text-decoration: none;
}
.container .nav-links a.active {
  font-weight: 600;
  color: #DE192B;
}
.container .nav-links a.sub-nav-link {
  font-size: 16px;
  margin-top: 10px;
}
.container .nav-links a.upper-nav-link {
  margin-top: 25px;
  font-size: 16px;
}
.container .nav-links a.lower-nav-link {
  font-size: 14px;
  margin-top: 15px;
}
.container .nav-links .company-name {
  font-weight: 600;
  font-size: 14px;
}
.container .nav-links .sub-nav-link {
  margin-left: 10px;
}
.container .body-wrapper {
  background: #EFF5FB;
}
.container .body-wrapper .top-bar {
  background: #EFF5FB;
  padding: 20px 20px 5px 20px;
  z-index: 1;
}
.container .body-wrapper .top-bar .search-anything {
  flex-grow: 1;
  margin-right: 20px;
  max-width: 450px;
}
.container .body-wrapper .top-bar .user-text {
  font-size: 14px;
  line-height: 21px;
  margin-left: 15px;
}
.container .body-wrapper .body-content {
  padding: 20px 20px 20px 20px;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .container .mobile-menu {
    background: #ffffff;
    padding: 20px;
  }
  .container .top-bar-mobile {
    padding: 15px 20px;
    background: #ffffff;
    border-bottom: 1px solid #DFDFDF;
  }
  .container .top-bar-mobile .user-text-mobile {
    font-size: 14px;
    font-weight: 600;
  }
  .container .mobile-body-wrapper {
    background: #EFF5FB;
    overflow: auto;
    flex-grow: 1;
  }
  .container .nav-footer {
    background: #ffffff;
    border-top: 1px solid #DFDFDF;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    flex-shrink: 0;
  }
}
.fp-body {
  margin-top: 30px;
  flex-grow: 1;
}
@media screen and (max-width: 767px) {
  .fp-body {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.fp-body .welcome-text {
  font-size: 30px;
  font-weight: bold;
  line-height: 46px;
}
.fp-body .sub-welcome-text {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 40px;
  text-align: center;
}
.fp-body .fp-content {
  white-space: pre-line;
  font-size: 18px;
  line-height: 35px;
}
@media screen and (max-width: 767px) {
  .fp-body .fp-content {
    font-size: 14px;
  }
}
.fp-body .fp-mobile-go-back-button {
  margin-top: 20px;
}

.fp-header {
  padding: 20px 40px;
}
