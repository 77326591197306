@import '../../styles/variables';

.loa-form-container {
    .loa-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        margin-bottom: 30px;

        @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 20px;
        }
    }

    .is-whole-day-option {
        margin-bottom: 20px;

        .is-whole-day-text {
            font-size: 16px;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }

    .cancel-button {
        margin-right: 20px;

        @media screen and (max-width: 767px) {
            margin-right: inherit;
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }

    .buttons-container {
        margin-top: 10px;
    }
}