@import '../../styles/variables';

.stop-view {
    font-size: 16px;
    line-height: 25px;

    @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
    }

    .stop-button {
        text-align: right;
    }

    .label {
        color: $neutral-3-color;
        font-size: 14px;
        line-height: 21px;

        &:not(.without-margin) {
            margin-top: 20px;
        }
    }
}
