.dashboard-container {
    // margin-top: 20px;
    height: 100%;

    .info-card {
        // margin-right: 20px;

        @media screen and (max-width: 768px) {
            margin-right: inherit;
            margin-top: 10px !important;
        }
    }

    .confirmed-transports-card {
        padding: 0px;
        margin-top: 20px;

        // flex-grow: 1;
        // height: 100%;
        .transports-header {
            padding: 15px;

            @media screen and (max-width: 767px) {
                padding: 10px;
                .totals-button{
                    margin-right: 10px;
                }
            }

        
        }

        .new-transport-link {
            margin-right: 15px;
        }

        .revenues-title {
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            margin-right: 5px;

            @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 21px;
            }

        }
        
        .no-revenues {
            padding: 15px;
        }
    }
}