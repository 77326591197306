.revenues-card {
    padding: 0px !important;

    .revenues-header {
        padding-top: 15px;
        padding-bottom: 0px;
        padding-left: 15px;
        padding-right: 15px;

        @media screen and (max-width: 767px) {
            padding: 10px;
        }

        .totals {
            margin-left: 10px;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                margin-left: inherit;
            }
        }
    }

    .no-revenues {
        padding: 15px;
    }
}