@import '../styles/variables';

.mobile-notifications{
    background: $white;
    height: 28px;
    width: 28px;
    border: 2px solid $neutral-1-color;
    border-radius: 3px;
    text-align: center;
    line-height: 23px;
    color: $neutral-1-color;
    .new-notifications-label{
        position: absolute;
        margin-left: 3px !important;
        margin-top: -6px;
    }
}