.information-modal {
    .mc-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;

        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .mc-description {
        flex-grow: 1;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 26px;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 22px;
        }
    }
}