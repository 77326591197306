@import '../../styles/variables';

.loa-card {
    padding: 0px !important;

    .loa-header {
        padding: 10px;
        padding-bottom: 0px;

        @media screen and (max-width: 767px) {
            padding-bottom: 10px;
        }

        .export-button {
            margin-left: 10px;
        }

        .loa-month-sel {
            margin-left: 10px;
        }
    }

    .mobile-loa-grouped-by-driver {
        padding: 10px;

        .d-header {
            margin-bottom: 5px;

            .driver-name {
                margin-left: 5px;
            }
        }

        .mobile-loa {
            margin-top: 5px;
            color: $neutral-3-color;
        }

    }
}