@import '../../styles/variables';

.info-card{
    flex-grow: 1;
    .title{
        font-size: 18px;
        font-weight: 600;
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
    .date{
        margin-top: 20px;
        font-size: 15px;
        line-height: 23px;
        @media screen and (max-width: 767px) {
            font-size: 13px;
            margin-top: 0px;
            color: $neutral-2-color;
        }
    }
}